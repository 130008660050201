<template>
  <section class="message task-created">
    <section>
      <hub-icon name="file-check-outline" size="xlg" />
      <div>
        <h4>{{ item.code }} ({{ item.description }})</h4>
        <div class="filed-on">
          <em><label>Filed on:</label> {{ new Date(item.date).toLocaleString() }}</em>
        </div>
      </div>
      <div class="metadata">
        <div v-for="mime of item.mime" :key="mime + Date.now()" style="display: inline">
          <hub-icon :name="getIcon(mime)" size="mdx" :title="mime" @click="download(mime)"></hub-icon>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Icon from './../../common/Icon';
import httpClient from '@/utils/httpClient';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    item: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    getIcon(mime) {
      switch (mime) {
        case 'pdf':
          return 'file-pdf-box';
        case 'docx':
          return 'file-word-box';
        case 'xml':
          return 'file-code-outline';
        default:
          return 'file-outline';
      }
    },
    async download(mime) {
      const fileToken = await httpClient.post('/api/uspto/token', {
        path: `/applications/${this.item.applicationId}/documents/${this.item.id}.${mime}/file/download`
      });
      const url = `/api/uspto/applications/${this.item.applicationId}/documents/${this.item.id}.${mime}/file/download?authToken=${fileToken}`;
      window.open(url, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.message.task-created {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;
  background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  grid-gap: 0.25rem;

  label {
    font-weight: 600;
  }

  em {
    font-size: 0.65rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.65rem;
  }

  i {
    cursor: pointer;
  }
}

.filed-on {
  font-size: 0.65rem;
}
</style>
