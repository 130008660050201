<template>
  <section v-if="isGetStreamRequestPending">
    <div class="loading">
      <hub-icon name="loading" spin size="xxlg"></hub-icon>
    </div>
  </section>
  <section v-else class="invention-stream">
    <header class="header">
      <div v-if="queueStatus.status">
        Synchronization <label style="font-weight: 600">{{ queueStatus.status }}</label> at
        {{ new Date(queueStatus.updatedAt || queueStatus.createdAt).toLocaleString('en-us') }}
      </div>
      <div v-else-if="applicationNumber">
        This inventions was not synchronized with USPTO. Please click 'Syncronize invention' button.
      </div>
      <div v-else>
        This inventions doesn't have USPTO application number yet and can not be synchronized
      </div>
    </header>
    <div v-if="isGetStreamRequestFailed" class="status-message">An error occurred while an attempt to get event stream for this invention.</div>
    <div v-else class="list-area" :class="{ center: !collection?.length }">
      <div v-if="isGetStreamRequestPending || isGetStreamRequestPending" style="text-align: center">
        <hub-icon name="loading" spin size="lg"></hub-icon>
      </div>
      <div v-else-if="!collection?.length" class="status-message">No files were received for this invention</div>
      <ul v-else class="stream-list">
        <li v-for="group of collection" :key="group.id" class="stream-list-item">
          <div class="group-header" @click="toggleGroup(group)">
            <div>
              <hub-icon v-if="expanded[group.id]" name="chevron-down" size="sm" class="expander-button"></hub-icon>
              <hub-icon v-else name="chevron-up" size="sm" class="expander-button"></hub-icon>
              <label>{{ groupTitle(group) }}</label>
            </div>
            <div class="timestamp">{{ new Date(group.timestamp).toLocaleString() }}</div>
          </div>
          <ul v-if="expanded[group.id]" class="ifw-group">
            <li v-for="item of group.items" :key="item.id">
              <hub-ifw-file :item="item" />
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <footer></footer>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Icon from '../../common/Icon.vue';
import usptoFileDownloadedLight from '../widgets/UsptoFileDownloadedLight';

export default {
  components: {
    'hub-icon': Icon,
    'hub-ifw-file': usptoFileDownloadedLight
  },
  props: {
    id: {
      type: String,
      required: true
    },
    references: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      expanded: []
    };
  },
  computed: {
    ...mapGetters({
      collection: 'ifw_stream/collection',
      flatList: 'ifw_stream/flatList',
      queueStatus: 'ifw_stream/queueStatus'
    }),
    ...mapState({
      isGetStreamRequestPending: s => s.ifw_stream.isRequestPending,
      isGetStreamRequestFailed: s => s.ifw_stream.isRequestFailed
    }),
    applicationNumber() {
      const usRef = this.references?.find(r => r.startsWith('US:'));

      if (!usRef) {
        return null;
      }

      return usRef
        .split(':')[1]
        .replaceAll('/', '')
        .replaceAll(',', '')
        .trim();
    }
  },
  async unmounted() {
    await Promise.all([
      this.$store.dispatch('ifw_stream/unsubscribe', { inventionId: this.$route.params.id }),
      this.$store.dispatch('ifw_stream/disconnect'),
      this.$store.dispatch('ifw_stream/reset')
    ]);
  },
  async created() {
    const promise = this.applicationNumber
      ? this.$store.dispatch('ifw_stream/load', { id: this.applicationNumber, inventionId: this.$route.params.id })
      : Promise.resolve();

    await Promise.all([
      this.$store.dispatch('ifw_stream/connect'),
      this.$store.dispatch('ifw_stream/subscribe', { inventionId: this.$route.params.id }),
      promise
    ]);
  },
  methods: {
    groupTitle(group) {
      const firstItem = group.items[0];
      const date = firstItem.date && new Date(firstItem.date).toLocaleDateString('en-US');
      const thisAbstracts = group.items.filter(i => i.code === 'ABST');

      if (thisAbstracts.length) {
        const abstracts = this.flatList.filter(d => d.code === 'ABST');
        const abstract = thisAbstracts.find(a => abstracts.indexOf(a) === abstracts.length - 1);
        if (abstract) {
          return `${date} - APPLICATION`;
        }
      }

      return `${date} - ${firstItem.code}`;
    },
    toggleGroup(group) {
      this.expanded[group.id] = !this.expanded[group.id];
    }
  }
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.list-area {
  display: flex;
  overflow-y: scroll;
  flex-direction: column-reverse;
  height: 100%;
}

.center {
  justify-content: center;
}

.status-message {
  text-align: center;
  font-style: italic;
}

.invention-stream {
  height: 100%;
  display: grid;
  grid-gap: 0.5rem;
  overflow-y: scroll;

  grid-template-rows: max-content minmax(0, 1fr) 1px;
  grid-template-columns: minmax(0, 1fr) 1px;

  > header {
    padding: 0.1rem;
    padding-bottom: 0;
    grid-column: 1/3;
    position: relative;
  }

  > footer {
    padding: 0 1.25rem;
    grid-column: 1/3;
  }

  .header {
    padding-top: 10px;
    padding-left: 20px;
    font-style: italic;
    font-size: 11px;
  }
}

.stream-list {
  padding: 0.5rem 1.25rem;
  padding-top: 0;
  margin-bottom: 0;
  display: grid;
  grid-gap: 0.5rem;
  align-content: flex-end;

  .ifw-group {
    padding-left: 0.25rem;
    padding-top: 0.5rem;
  }

  .stream-list-item {
    background-color: var(--theme-surface);
    border-radius: 3px;
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    grid-gap: 0.25rem;

    label {
      font-weight: 600;
      cursor: pointer;
    }

    .stream-list-item-header {
      margin-bottom: 0.25rem;
      display: flex;
      justify-content: space-between;
    }

    .group-header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      .expander-button {
        margin-right: 5px;
      }
    }

    .timestamp {
      display: contents;
      font-style: italic;
      font-size: 0.65rem;
    }
  }
}
</style>
